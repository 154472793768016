/** navigation */

.top_section {
  background-color: #05213e;
  width: 100%;
  z-index: 1000;
  align-items: center;
  position: fixed;
  top: 0px;
}

.top_section_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* background: red; */
  gap: 28px;
  height: 29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 40px;
  /* padding: 8px 15px; */
  z-index: 1001;
}

.top_section_text {
  margin-right: 44px;
  color: var(--secondary);
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.5%;
}

.site_nav {
  background-color: var(--primary);
  align-items: center;
  color: #ffffff;
  position: fixed;
  width: 100%;
  height: var(--nav-height-min);
  /* left: 0px;
  top: 0px;
  z-index: 1000; */
  top: 29px;
  z-index: 800;
}

.show {
  opacity: 1;
  transition: 500s;
}

.hide {
  opacity: 0;
  transition: 500s;
}

.site_nav .nav_logo {
  margin: 14px 0px 14px 19px;
  width: 120px;
  height: 35.56px;
  cursor: pointer;
}

.nav_title {
  font-family: "Nunito Sans";
  font-weight: 600;
  line-height: 24px;
  font-size: 1.125em;
}

.nav_subtitle {
  font-weight: 400;
  font-size: 1.125em;
  margin-left: 9px;
  line-height: 24px;
}

.site_nav .nav_menu {
  grid-column: 5 / 9;
  /* margin: 13px 9px 12px 0px; */
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}

.site_nav .nav-demo {
  margin-left: 13px;
}

.site_nav img {
  width: 24px;
  height: 24px;
  /* margin: 0 0 0 13px; */
}

.site_nav img.flag {
  width: 22px;
  height: 14px;
  margin-top: 2px;
}

.site_nav_language {
  position: static;
  bottom: 149px;
  max-width: 82vw;
  text-align: right !important;
}

.nav_desktop {
  display: none;
}

.spannable {
  flex-direction: column;
  max-width: 40px;
  cursor: pointer;
}

.spannable.min {
  max-width: 36px;
  max-height: 28px;
}

/* .spannable .dropdown_menu {
  display: flex;
  position: relative;
  top: 34px;
  right: 210px;
} */

.spannable .dropdown_menu {
  display: flex;
  position: absolute;
  top: 100px;
  margin-left: 34px;
}

.spannable .dropdown_menu {
  display: flex;
}

/* .spannable .dropdown_full {
  display: flex;
  position: relative;
  top: 18px;
  right: 90vw;
  padding-top: 61px;
  width: 104vw;
  height: 100vh;
} */

.dropdown_menu,
.dropdown_full {
  display: none;
  background-color: white;
  color: var(--primary);
  font-weight: 400;
  /* border-radius: 4px; */
  flex-direction: column;
  padding: 0px 48px 0px 24px;
  align-items: flex-start;
  width: max-content;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
}

.dropdown_full {
  padding: 0px 30px 0px 30px;
  color: #ffffff;
  background-color: var(--primary);
  display: flex;
  position: absolute;
  top: 66px;
  left: 0;
  padding-top: 61px;
  padding-bottom: 62px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  z-index: 2000;
}

.dropdown_full a button {
  position: static;
  margin-top: auto;
  max-width: 84vw;
}
/* .arrow-up { */

.dropdown_menu::before {
  width: 0;
  height: 0;
  content: "";
  position: relative;
  bottom: 15px;
  left: 36%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
}

.dropdown_menu.right::before {
  left: 104%;
}

.dropdown_menu > *,
.dropdown_full > * {
  margin-bottom: 16px;
  width: 190px;
  text-align: left;
  text-overflow: ellipsis;
}

.dropdown_full > * {
  width: 86vw;
  margin-bottom: 32px;
}

/* .dropdown_menu > *:hover, .dropdown_full > *:hover {
  opacity: 0.5;
} */

/* .dropdown_menu div:last-child,
.dropdown_full div:last-child {
  margin-bottom: 24px;
  padding-bottom: 0px;
} */

.dropdown_full a button {
  position: absolute;
  bottom: 110px;
  max-width: 84vw;
}

.nav_demo {
  width: fit-content;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--secondary);
  background-color: transparent;
  border: 1px solid var(--secondary);
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.nav_demo:hover {
  text-decoration: none !important;
}

.nav_list_box {
  position: relative;
}

.dropdown_container {
  position: static;
}

.solutions_dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  padding: 16px;
  margin-top: 8px;
}

.solutions_dropdown_wrapper {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-top: 12px;
}

.nav_list_box:hover .solutions_dropdown {
  display: flex;
}

.solutions_dropdown {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.solutions_title {
  color: var(--primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.nav_list_box:hover .nav_title {
  color: var(--secondary);
}

.nav_list_box:hover .nav_title {
  color: var(--secondary);
}

.solutions_dropdown_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  width: 253px;
  gap: 8px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.solutions_dropdown_item:hover {
  background: linear-gradient(180deg, #003366 0%, #125190 44.5%, #2877C5 100%);
  text-decoration: none;
}
.solutions_dropdown_item:hover .solutions_activity_name {
  color: white;
}

.color_icon {
  fill: url(#gradient1);
}
.solutions_dropdown_item:hover .color_icon {
  fill: white;
  background-color: #003580;
}
.solutions_icon {
  border-radius: 4px;
  /* padding: 8px; */
  align-items: center;
  width: 40px;
  height: 40px;
  /* border: 1px solid #003366;
  transition: background-color 0.3s, border-color 0.3s; */
}

.solutions_icon img  {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.solutions_activity_name {
  font-family: "Inter";
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.13%;
  color: #003366;
}



.solution_dropdown_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solutions_dropdown_item_mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 28px 12px 28px;
}

.solutions_icon_mobile {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.solutions_icon_mobile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.solutions_activity_name_mobile {
  display: flex;
  align-items: center;
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15%;
  height: 100%;
}

.triangle_up {
  position: absolute;
  top: 29px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 50px solid white;
}

.custom_list_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.switch_language_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/assets/language_icon.svg");
  height: 20px;
  width: 20px;
  /* margin: 0px 12px 0px 12px; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  /* position: static; */
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #E0ECF9 !important;
  margin: 10px 0 !important;
}

.icon_down {
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch_language_wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.switch_language_wrapper:hover {
  cursor: pointer;
}
.lang_nav_display {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.switch_language_wrapper:hover .lang_nav_display {
  color: var(--secondary);
}

.switch_language_wrapper:hover .switch_language_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/assets/language_icon_yellow.svg");
  height: 20px;
  width: 20px;
  /* margin: 0px 12px 0px 12px; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.global_icon {
  margin-right: 18px !important;
  cursor: pointer;
  filter: invert(87%) sepia(43%) saturate(2179%) hue-rotate(328deg)
    brightness(115%) contrast(89%);
}

.language_list__box {
  position: absolute;
  top: 30px;
  background-color: #fff;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
}

.language {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lang-display {
  font-family: "Inter";
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.language a {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  /* margin-left: 5px; */
  color: var(--primary);
  text-decoration: none;
}

.button_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  /* padding-left: 1200px; */
}

.nav_contact_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--secondary);
  background-color: transparent;
  border: 1px solid var(--secondary);
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
}

.nav_free_trial_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--secondary);
  border-radius: 6px;
  color: #003366;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: fit-content !important;
  flex-grow: 0;
  flex-shrink: 0;
}

.nav_contact_button:hover {
  background-color: transparent;
  border: 1px solid white;
  text-decoration: none;
  color: white;
}

/** very small phones (galaxy fold) */

@media screen and (max-width: 285px) {
  .site_nav .nav_logo {
    margin-right: 10px;
    width: 100px;
  }
  .nav_title {
    font-size: 1.5em;
  }
  .nav_subtitle {
    font-size: 1.5em;
  }
  .top_section {
    display: none;
  }
  .site_nav {
    top: 0;
  }
}

/** small phones styles */

@media screen and (max-width: 395px) {
  .site_nav .nav-demo {
    margin-left: 0px;
  }
  .dropdown_full > * {
    width: 84vw;
  }
  .dropdown_full a button {
    max-width: 82vw;
  }
  .nav_title {
    font-size: 1.5em;
  }
  .nav_subtitle {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 760px) {
  .top_section {
    display: none;
  }
  .site_nav {
    top: 0;
  }
}

/** mini tab styles */

@media screen and (min-width: 760px) {
  .site_nav .nav_menu {
    grid-column: 10 / 11;
  }
  .dropdown_full a button {
    max-width: 93vw;
  }
  .top_section {
    display: none;
  }
  .site_nav {
    top: 0;
  }
}

/** small laptop / big tab styles */

@media screen and (min-width: 992px) {
  /* .site_nav {
    height: var(--nav-height);
    padding: 0px 159px 0px 151px;
    align-items: center;
  } */
  .divider {
    height: 1px;
    width: 100%;
    background-color: #E0ECF9 !important;
    margin: 10px 0 !important;
  }
  
  .top_section {
    display: flex;
  }
  .site_nav {
    height: var(--nav-height);
    align-items: center;
    top: 29px;
  }
  .site_nav .nav-demo {
    display: none;
  }
  .site_nav .nav_menu {
    display: none;
  }
  .site_nav .nav_logo {
    grid-column: 2 / 3;
    width: 162px;
    height: 48px;
    margin: 18px 0 0 0;
  }
  /* .nav_desktop {
    display: flex;
    flex-basis: 1fr;
    justify-content: space-between;
    grid-column: 4 / 13;
    margin: 30px 0px;

    height: 24px;
    font-weight: 700;
    line-height: 24px;
  } */
  .nav_desktop {
    display: flex;
    flex-basis: 1fr;
    justify-content: space-between;
    grid-column: -1 / 13;
    align-items: center;
    font-weight: 700;
    line-height: 24px;
  }
  .nav_desktop > * {
    margin-right: 20px;
  }
  .nav_fit_content {
    max-width: fit-content !important;
  }
  .nav_list_box {
    /* display: flex;
    justify-content: center !important;
    align-items: center !important;
    background-color: red; */
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    align-items: center;
    height: 100%;
    width: auto;
    /* min-width: 120px; */
  }

  .nav_desktop_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 20px;
  }

  .nav_list_box .faq {
    min-width: 0px;
  }
  .nav_desktop .nav_list_box:hover {
    border-bottom: 4px solid var(--secondary);
    text-decoration: none;
    cursor: pointer;
  }
  .nav_desktop .nav_list_box img {
    margin-right: 10px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  }
  .nav_title {
    font-weight: 700;
    font-size: 14px;
    margin-top: 30px;
  }
  .flex_center {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .nav_title:hover {
    text-decoration: none;
  }
  .nav_desktop .nav_list_box a {
    display: flex;
    justify-content: flex-start;
  }
  .nav_desktop .nav_list_box:hover > .nav_title,
  .nav_desktop .nav_list_box:hover > .nav_title:hover {
    color: var(--secondary);
  }
  /* .nav_desktop .nav_list_box:nth-child(1) {
    margin-left: 25px;
  } */
  .nav_desktop .nav_list_box:nth-child(5) {
    margin-right: 60px;
  } 

  /* .spannable {
    display: flex;
    position: relative;
    flex-basis: 20%;
    max-width: 86px;
  } */
  .spannable {
    display: flex;
    flex-basis: 20%;
    max-width: 86px;
  }
  .spannable.min {
    width: 52px;
    max-height: 22px;
    margin-right: 18px;
  }
  .dropdown_menu {
    padding-right: 60px;
  }
  .dropdown_menu > * {
    width: fit-content;
  }
  .dropdown_menu,
  .dropdown_full {
    border-top: 1px solid white;
  }
  /* .spannable .dropdown_menu {
    top: 42px;
    right: -30px;
  } */
  .spannable.min .dropdown_menu {
    top: 46px;
    right: 50px;
  }
  .dropdown_menu,
  .dropdown_full {
    display: none;
  }
}

/** desktop styles */
/* @media screen and (max-width: 1200px) {
  .nav_desktop_wrapper {
    margin-left: 120px;
  }

} */

@media screen and (min-width: 1200px) {
  .switch_language_container {
    margin: 0px 4px 0px 0px !important;
  }
  .nav_desktop .nav_list_box:nth-child(1) {
    margin-left: 25px;
  }
  .nav_desktop .nav_list_box:nth-child(5) {
    margin-right: 170px;
  }
}
