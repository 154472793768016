.icon_fg_caretDoubleDown {
    fill: white;  
    transition: all 0.3s ease;
}

.banner_explore_btn:hover .icon_fg_caretDoubleDown {
    fill: var(--secondary) !important;  
}

.icon_fg_caretUp {
    fill: white;  
    transition: all 0.3s ease;
}

.icon_fg_copyRight {
    fill: white;  
    transition: all 0.3s ease;
}

.icon_fg_location {
    color: #162533;
    fill: #162533;  
    transition: all 0.3s ease;
}

.icon_fg_envelop {
    color: #162533;
    fill: #162533;  
    transition: all 0.3s ease;
}

.icon_fg_phone {
    color: #162533;
    fill: #162533;  
    transition: all 0.3s ease;
}

.icon_clock_count_down, .icon_check_circle {
    object-fit: contain;
    min-width: auto;
    max-width: 106px;
    width: 100%;
    min-height: auto;
    max-height: 106px;
    height: 100%;
    transition: all 0.3s ease;
}




@media screen and (max-width: 992px) {
    .icon_clock_count_down, .icon_check_circle {
        min-width: auto;
        max-width: 80px;
        width: 100%;
        min-height: auto;
        max-height: 80px;
        height: 100%;
    }
}

